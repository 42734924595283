import { render, staticRenderFns } from "./_admin-portal-api-manage-user.vue?vue&type=template&id=ecd72650&"
import script from "./_admin-portal-api-manage-user.vue?vue&type=script&lang=js&"
export * from "./_admin-portal-api-manage-user.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports